<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                               {{ $t("message.completed_tasks") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart custom__scroll">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.deal_id.show">
                                {{ columns.deal_id.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.begin_date.show">
                                {{ columns.begin_date.title }}
                            </th>

                            <th v-if="columns.end_date.show">
                                {{ columns.end_date.title }}
                            </th>

                            <th v-if="columns.user_id.show">
                                {{ columns.user_id.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>
                            <th v-if="columns.deal_id.show">
                                <select-deal
                                    :size="'mini'"
                                    :placeholder="$t('message.deal')"
                                    :id="filterForm.deal_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.deal_id"
                                >
                                </select-deal>
                            </th>
                            <th v-if="columns.name.show">
                                <crm-input
                                    :placeholder="columns.name.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                ></crm-input>
                            </th>

                            <th v-if="columns.begin_date.show">
                                <crm-date-picker
                                    :placeholder="columns.begin_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.begin_date"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.end_date.show">
                                <crm-date-picker
                                    :placeholder="columns.end_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.end_date"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.user_id.show">
                                <select-user
                                    :size="'mini'"
                                    :placeholder="$t('message.user')"
                                    :id="filterForm.user_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"
                                >
                                </select-user>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="task in list"
                            :key="task.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ task.id }}
                            </td>
                            <td v-if="columns.deal_id.show">
                                {{ task.deal ? task.deal.name : "" }}
                            </td>
                            <td v-if="columns.name.show">
                                {{ task.name }}
                            </td>

                            <td v-if="columns.begin_date.show">
                                {{ task.begin_date }}
                            </td>

                            <td v-if="columns.end_date.show">
                                {{ task.end_date }}
                            </td>

                            <td v-if="columns.user_id.show">
                                {{ task.user ? task.user.name : "" }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ task.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ task.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <el-dropdown @command="restoringTask">
                                    <span class="el-dropdown-link more_icons">
                                        <i
                                            class="
                                                el-icon-arrow-down
                                                el-icon-more-outline
                                            "
                                        ></i>
                                    </span>
                                    <el-dropdown-menu
                                        slot="dropdown"
                                        size="mini"
                                    >
                                        <el-dropdown-item
                                            :command="{
                                                action: 'show',
                                                id: task.id,
                                            }"
                                            icon="el-icon-view el-icon--left"
                                        >
                                            {{ $t("message.show") }}
                                        </el-dropdown-item>
                                        <el-dropdown-item
                                            :command="{
                                                action: 'restore',
                                                id: task.id,
                                            }"
                                            icon="el-icon-upload2 el-icon--left"
                                        >
                                            {{ $t("message.restore") }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </td>
                        </tr>
                    </transition-group>
                </table>

                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="visibleShow"
                    size="80%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-show 
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-show>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import selectUser from "@/components/inventory/select-users";
import selectDeal from "@/components/inventory/select-deal";
import CrmShow from "./components/show.vue";
// import crmShow from "./crm-show";

export default {
    name: "deletedTasks",
    mixins: [list],
    components: {
        selectUser,
        selectDeal,
        CrmShow,
    },
    data() {
        return {
            visibleShow: false,
            crmShow: false,
        };
    },

    computed: {
        ...mapGetters({
            list: "tasks/archivedTasks",
            columns: "tasks/columns",
            pagination: "tasks/pagination",
            filter: "tasks/filter",
            sort: "tasks/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "tasks/archivedTasks",
            setPagination: "tasks/setPagination",
            updateSort: "tasks/updateSort",
            updateFilter: "tasks/updateFilter",
            updateColumn: "tasks/updateColumn",
            updatePagination: "tasks/updatePagination",
            show: "tasks/show",
            empty: "tasks/empty",
            delete: "tasks/destroy",
            refreshData: "tasks/refreshData",
            restoreTask: "tasks/restoreTask",
        }),
        check: function (column, event) {
            this.updateColumn({ key: column, value: event });
        },
        restoringTask(command) {
            if (command.action === "restore") {
                this.$confirm(
                    this.$t('message.Are you sure you want to activate the task ?'),
                    this.$t('message.warning'),
                    {
                        confirmButtonText: "Да",
                        cancelButtonText: "Нет",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.restoreTask(command.id)
                            .then((res) => {
                                this.$alert(res);
                                this.fetchData();
                            })
                            .catch((err) => {
                                this.$alert(err);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: this.$t('message.operation_canceled'),
                        });
                    });
            } else {
                this.visibleShow = true;
                this.selectedItem = command;
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("tasks/EMPTY_ARCHIVED_TASKS_LIST");
        next();
    },
};
</script>

